<template>
  <div class="not-found page-content">
      <p class="oops">Oops!</p>
      <h1 class="text-175">We can't seem to find the page you were looking for.</h1>
      <br>
      <router-link :to="{ name: 'home' }" class="signup px-2 md:px-8 py-1 md:py-2 bg-dcgreen text-white text-sm font-semibold uppercase font-futura">Take me home</router-link>
  </div>
</template>

<script>

export default {
  name: 'notfound',
}
</script>

<style scoped lang="scss">
    .not-found {
        max-width: 500px;
        margin: auto;
        padding: 90px 0;

        .oops {
            font-size: 5rem;
        }
    }
</style>
